<template>
    <div class="products__wrapper" id="products">
        <h2 class="products__title block-title">PRODUCTS</h2>
        <div class="products__list-container">
            <div class="products__item" v-for="prod in products" v-bind:key="prod">
                <img :src="require('../assets/products_cover/'+prod.imgName)" :alt="prod.alt">
                <div class="products__info">
                    <div class="products__info-container">
                        <h2 class="product__title">{{prod.title}}</h2>
                    </div>
                    <div class="products__shops">
                        <vShops :shopsArr="prod.shops"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vShops from "./shops"
    export default {
        name: "products",
        components:{
            vShops
        },
        data(){
            return{
                products:{
                    product1:{
                        title:'Square Wall Calendar 2024 (7x14 Inch)',
                        alt:'Karate Cats Wall calendar 2024 cover',
                        imgName:'calendar_cover_7inch.jpg',
                        shops:[
                            {
                                shopName:'AMAZON.COM',
                                href:'https://amzn.to/3ZXqtAE'
                            },
                            {
                                shopName:'CALENDARS.COM',
                                href:'https://www.calendars.com/shop/karate-cats-2024-mini-wall-calendar/202400004670'
                            },
                        ]
                    },
                    product2:{
                        title:'Square Wall Calendar 2024 (12x24 Inch)',
                        alt:'Karate Cats wall calendar 2024 cover',
                        imgName:'calendar_cover_12inch.jpg',
                        shops:[
                            {
                                shopName:'AMAZON.COM',
                                href:'https://amzn.to/3mKkS2q'
                            },
                            {
                                shopName:'CALENDARS.COM',
                                href:'https://www.calendars.com/shop/karate-cats-2024-wall-calendar/202400004897'
                            },
                        ]
                    },
                },
            }
        }
    }
</script>

<style>
    .products__list-container{
        max-width: 1020px;
        width: 100%;
        margin: auto;
    }
    .products__item{
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        grid-gap: 20px;
        margin-bottom: 20px;
    }
    .products__item img{
        max-width: 100%;
        width:100%;
        /*box-shadow: 1px 1px 6px #1c1c1cb5;*/
    }
    .product__title{
        font-size: 30px;
    }
    .products__info{
        font-family: "Arial Black";
        display: grid;
       /* grid-template-rows: 1fr 3fr;*/
        grid-template-rows: 0fr 1fr;
        justify-items: center;

    }
    .products__info-container ul li{
        padding: 10px 0 0 25px;
        list-style: none;
        position: relative;
    }

    .products__info ul li:before{
        content: "✓";
        position: absolute;
        width:5px;
        height: 5px;
        color:green;
        left: 0;
    }

    .products__shops{
        margin: auto;
    }

    @media only screen and (max-width:1020px) {
        .products__list-container {
            width: 95%;
        }
    }

    @media only screen and (max-width:768px) {
        .products__item {
            grid-template-columns: 1fr;
        }
        .products__info {
            grid-template-rows: 0fr 1fr;
        }
        .products__shops{
            margin: auto;
        }
    }
</style>