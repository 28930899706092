<template>
    <div class="mini-gallery__wrapper" id="cats">
        <div class="mini-gallery__title block-title">OUR CATS</div>
        <div class="mini-gallery__container">
            <div class="mini-gallery__item"
                 v-for="photo in photos_mini"
                 :key="photo"
                 :style="{gridArea: photo.alias}"
                >
                <img :src="require('../assets/gallery/'+ photo.imgSrc)"
                     :alt="photo.pose"/>
            </div>
           <router-link to="/gallery"><div class="mini-gallery__button button">SHOW MORE</div></router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "gallery-mini",
        props:{
            preloaderBit:{
                type:Boolean
            }
        },
        data(){
            return{
               photos_mini:[
                    {imgSrc:"1.jpg",alias:'img7'},
                    {imgSrc:"2.jpg",alias:'img2'},
                    {imgSrc:"3.jpg",alias:'img3'},
                    {imgSrc:"4.jpg",alias:'img8'},
                    {imgSrc:"5.jpg",alias:'img1'},
                    {imgSrc:"6.jpg",alias:'img6'},
                    {imgSrc:"7.jpg",alias:'img5'},
                    {imgSrc:"8.jpg",alias:'img4'},
                    {imgSrc:"9.jpg",alias:'img9'}
                ],
            }
        },
        mounted: function () {
        },
        methods: {

        }
    }
</script>

<style>
    img{
        max-width: 100%;
    }

    .mini-gallery__container{
        display: grid;
        grid-auto-columns: 1fr;
        position: relative;
        grid-template-areas:
                'img1 img1 img1 img2 img3 img3 img5 img7 img7 img8'
                'img1 img1 img1 img4 img3 img3 img6 img7 img7 img9';
    }

    .mini-gallery__item>img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    .mini-gallery__item{
        position: relative;
        overflow: hidden;
    }
    .mini-gallery__item-info{
        position: absolute;
        top:0;
        padding-top: 45%;
        height: 100%;
        width:100%;
        text-align: center;
        background: #000;
        font-family: "Arial Black";
        color: white;
        opacity: 0;
    }
    .mini-gallery__button{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        margin: auto;
        width: 120px;
        text-align: center;
        -webkit-animation: 3s tremor ease-out infinite;
        animation: 3s tremor ease-out infinite;
        user-select: none;
    }

    .mini-gallery__button a{
        text-decoration: none;
        color: white;
    }

    @keyframes tremor {
        0%, 5% {
            left: -2px;
            -webkit-transform: translateX(-4%);
            transform: translateX(-4%);
        }
        5%, 10% {
            left: 2px;
            -webkit-transform: translateX(4%);
            transform: translateX(4%);
        }

        10%, 15% {
            left: -2px;
            -webkit-transform: translateX(-4%);
            transform: translateX(-4%);
        }
        15%, 20% {
            left: 2px;
            -webkit-transform: translateX(4%);
            transform: translateX(4%);
        }
        20%, 25% {
            left: -2px;
            -webkit-transform: translateX(-4%);
            transform: translateX(-4%);
        }
        25%, 30% {
            left: 2px;
            -webkit-transform: translateX(4%);
            transform: translateX(4%);
        }
        30%, 35% {
            left: -2px;
            -webkit-transform: translateX(-4%);
            transform: translateX(-4%);
        }
        35%, 100% {
            left: 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }
    @media only screen and (max-width:1100px) {
        .mini-gallery__container{
            grid-template-areas:
                    'img1 img1 img2 img3 img3'
                    'img1 img1 img4 img3 img3'
                    'img5 img7 img7 img7 img8'
                    'img6 img7 img7 img7 img9'
        }
    }
</style>